<template>
  <div>
    <v-row class="justify-center pt-16">
      <v-col  cols="10" sm="10" md="4" lg="4" xl="4" class="d-flex justify-center flex-wrap">
        <span class="text-h3 font-weight-black my-8" style="color: #439798;">快工序</span>

        <v-container>
          <v-text-field
            label="员工号"
            hide-details="auto"
            v-model="personID"
            color="#439798"
          ></v-text-field>
          <v-text-field color="#439798" label="员工密码" v-model="InputCode">
          </v-text-field>
          <v-btn color="#439798" width="100%" class='mt-6 text-capitalize mx-auto white--text text-body-2 text-sm-body-2 text-md-body-1 text-lg-h6 text-xl-h6 font-weight-black' @click='handleEmailLogin' :loading="LoginLoading">
            登录
          </v-btn>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'
import { requestPost } from '@/utils/SystemUtils/request.js';


export default {

  name: 'YA_Login',
  data() {
    return {
      personID: '',
      InputCode: '',
      LoginLoading: false,
      isLogin: false,
    }
  },
  components: {
  },
  created() {
    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;
  },
  mounted() {

  },
  activated() {
  },
  methods: {
    async handleEmailLogin() {
      if (this.isLogin) {
        this.$store.commit('HandleSnackbar', '您已经登陆了');
      } else {
        if (this.InputCode) {
          this.LoginLoading = true;

          let request_header = {};

          let request_json = {
            'personID': this.personID,
            'personCode': this.InputCode,
          };
          let request_url = '/requestLogin';
          const res = await requestPost(request_header, request_json, request_url);

          if (res.statusCode == 1) {
            this.$store.commit('HandleSnackbar', '登录成功');

            setLocalStorage('kuaigongxuUser', this.personID);

            this.$store.commit('HandlepersonID', this.personID);
            this.$store.commit('HandleisLogin', true);

            this.personID = '';
            this.InputCode = '';

            this.LoginLoading = false;

            if (this.$router.currentRoute.path !== '/PriceForm') {
              this.$router.push('/PriceForm');
            }
          } else {
            this.$store.commit('HandleSnackbar', '登录失败 : ' + res.statusInfo);
            this.LoginLoading = false;
          }
        } else {
          this.$store.commit('HandleSnackbar', '请输入密码');
        }
      }
    },
  }

};
</script>

<style scoped>
</style>