<template>
  <div>
    <v-system-bar height="66" app style="background-color: #439798;" class="d-flex justify-space-between">
      <div class="d-flex">
        <div class="ml-n2 d-flex align-center justify-space-between" style="width: 240px;"> 
          <span class="ml-6 white--text text--darken-4 text-h6 font-weight-black">快工序</span>
        </div>
      </div>
      
      <div class="d-flex align-center mx-2">
        <YA_User v-if="isLogin" />
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="grey-darken" v-bind="attrs" v-on="on" small class="mx-3" icon @click="handleLogin">
              <v-icon size="30" color="grey lighten-3">mdi-account-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>登录</span>
        </v-tooltip>
      </div>
    </v-system-bar>
  </div>
</template>

<script>
import YA_User from '@/components/SocialComponents/YA_User.vue';
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'
import { requestPost } from '@/utils/SystemUtils/request.js';

export default {
  name: 'TopView',

  components: {
    YA_User,
  },

  data() {
    return {
      isLogin: false,
      isProUser: false,
    }
  },

  created() {
    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;
  },

  methods: {
    handleLogin() {
      let url='';
      if (!this.isLogin) {
        url = '/Login'
      } else {
        url = '/Home'
      }
      if (this.$route.path !== url) {
        this.$router.push({ path: url});
      }
      
    },

  }
};
</script>

<style scoped>
</style>
