<template>
  <div>
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'
import { requestPost } from '@/utils/SystemUtils/request.js';

export default {
  name: 'BeginView',

  components: {
  },

  data() {
    return {
      factoryID: '',
      isLogin: false,
    }
  },

  created() {
    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
        if (this.isLogin) {
          this.handleGetUserMsg();
        }
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;

    this.$store.watch(
      (state) => state.userMsg.factoryID, () => {
        this.factoryID = this.$store.getters.getfactoryID;
        if (this.factoryID !== '') {
          this.handleGetFactoryAllMsg();
        }
      }, {
        deep: true
      }
    );
    this.factoryID = this.$store.getters.getfactoryID;

    this.$store.watch(
      (state) => state.factoryMsg.refreshFactoryMsg, () => {
        if (this.$store.getters.getrefreshFactoryMsg) {
          this.handleGetFactoryAllMsg()
        }
      }, {
        deep: true
      }
    );

    if (getLocalStorage('kuaigongxuUser')) {
      this.$store.commit('HandlepersonID', getLocalStorage('kuaigongxuUser'));
      this.$store.commit('HandleisLogin', true);
      let url='/PriceForm';
      if (this.$route.path !== url) {
        this.$router.push({ path: url});
      }
    }
  },

  mounted() {
  },

  methods: {
    async handleGetUserMsg() {
      this.$store.commit('HandleloadingMsg', '获取用户信息中');

      let request_header = {
        personID: getLocalStorage('kuaigongxuUser'),
      };
      let request_json = {};

      let request_url = '/requestGetUserMsg';
      const res = await requestPost(request_header, request_json, request_url);

      if (res.statusCode == 1) {
        this.$store.commit('HandlepersonName', res.data[0].personName);
        this.$store.commit('HandlecharaterID', res.data[0].charaterID);
        this.$store.commit('HandlefactoryID', res.data[0].factoryID);

        this.$store.commit('HandleloadingMsg', '');
      } else {
        this.$store.commit('HandleSnackbar', 'Failed to retrieve user information:' + res.statusInfo);
      }
    },

    async handleGetFactoryAllMsg() {
      this.$store.commit('HandleloadingMsg', '获取工厂信息中');

      let request_header = {
        factoryID: this.factoryID,
      };
      let request_json = {};

      let request_url = '/requestGetFactoryAllMsg';
      const res = await requestPost(request_header, request_json, request_url);

      if (res.statusCode == 1) {
        this.$store.commit('HandlefactoryPersons', res.data.factoryPersons);
        this.$store.commit('HandlefactoryFunctions', res.data.factoryFunctions);
        this.$store.commit('HandlefactoryName', res.data.factoryMsg[0].factoryName);
        this.$store.commit('HandlerefreshFactoryMsg', false);

        this.$store.commit('HandleloadingMsg', '');
      } else {
        this.$store.commit('HandleSnackbar', 'Failed to retrieve user information:' + res.statusInfo);
      }
    },

  }
};
</script>
