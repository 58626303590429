<template>
  <div class="mx-2 my-2">
    <div class="d-flex justify-space-between align-center">
      <v-btn text @click="handleBack">
        <v-icon color="black">mdi-chevron-left</v-icon>
        <span class="black--text font-weight-black">返回</span>
      </v-btn>
    </div>
    <div class="mt-4 mb-2 mx-4">
      <div class="d-flex align-center">
        <v-text-field
          v-model="orderFormName"
          counter
          color="#439798"
          maxlength="20"
          label="流转单名"
          prepend-icon="mdi-rename"
        ></v-text-field>
        <v-btn icon @click="handleSaveModify" >
          <v-icon>mdi-content-save-check</v-icon>
        </v-btn>
      </div>
      <v-menu
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="orderFormDate"
            label="流转单日期"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
            color="#439798"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="orderFormDate"
          @input="menu2 = false"
          @change="handleSaveModify"
        ></v-date-picker>
      </v-menu>

      <div class="d-flex align-center">
        <v-text-field
          v-model="orderFormNum"
          color="#439798"
          label="工单总件数"
          prepend-icon="mdi-counter"
        ></v-text-field>
        <v-btn icon @click="handleSaveModify" >
          <v-icon>mdi-content-save-check</v-icon>
        </v-btn>
      </div>

      <div class="d-flex justify-center mb-4" @click="openFileInput" v-if="orderFormDetail.pictureUrl == ''">
        <v-btn
          color="#439798"
          class="white--text"
          :loading="photoLoading"
        >
          <span class="mx-6">上传图片</span>
        </v-btn>

        <input
          ref="fileInput"
          type="file" 
          accept="image/*"
          style="display: none;"
          @change="handleFileUpload"
        >
      </div>
      <div v-else class="mb-2 d-flex justify-center align-center">
        <v-img class="shrink ma-2" contain :src="orderFormDetail.pictureUrl" transition="scale-transition" width="60" />
        <v-btn
          color="error"
          text
          @click="handleDeleteFile"
        >
          <span class="mx-6">删除图片</span>
        </v-btn>
      </div>


      <v-expansion-panels flat outlined accordion hover>
        <v-expansion-panel
          v-for="(orderFormItem, index) in orderFormType"
          :key="index"
        >
          <v-divider></v-divider>
          <v-expansion-panel-header>
            <div class="d-flex justify-space-between">
              <div class="d-flex align-center">
                <span class="ml-2 text-body-1 font-weight-bold">{{ orderFormItem.personName }}</span>
              </div>
            </div>
            <template v-slot:actions>
              <v-icon color="#439798">
                $expand
              </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div>
              <v-list three-line>
                <div v-for="(item, functionIndex) in orderFormItem.functionItems" :key="functionIndex">
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="d-flex align-center">
                        <span class="text-body-1 font-weight-bold">{{ item.functionName }}</span>
                        <div class="mt-1">
                          <span class="text-body-2 grey--text font-weight-bold ml-2">单价：¥{{ item.functionPrice }}</span>
                          <span class="text-body-2 grey--text font-weight-bold ml-4">总价：¥{{ item.sumPrice }}</span>
                        </div>
                        <v-btn icon small class="ml-4" @click="handleDeleteStep(index, functionIndex)">
                          <v-icon size="24" color="grey lighten-1">mdi-delete</v-icon>
                        </v-btn>
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <div class="ml-4 d-flex align-center">
                          <p class="text-body-1 grey--text font-weight-bold mt-2">数量：</p>
                          <v-text-field
                            v-model="item.functionNumber"
                            color="#439798"
                            flat
                            dense
                            class="ml-2"
                          ></v-text-field>
                          <v-btn icon @click="handleNumChange(index, functionIndex)" >
                            <v-icon>mdi-content-save-check</v-icon>
                          </v-btn>
                        </div>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </v-list>
            </div>
            <div class="d-flex justify-center">
              <v-menu
                rounded="lg"
                offset-y
              >
                <template v-slot:activator="{ attrs, on }">
                  <v-btn
                    color="#439798"
                    class="white--text"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span class="mx-6">增加工序</span>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(item, factoryFunctionIndex) in factoryFunctions"
                    :key="factoryFunctionIndex"
                    link
                    @click="handleAddStep(item, index)"
                  >
                    <v-list-item-title v-text="item.functionName"></v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    
  </div>
</template>

<script>
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'
import { requestPost } from '@/utils/SystemUtils/request.js';
import { getBaseURL } from '@/utils/SystemUtils/url.js'

export default {
  name: 'DetailComponent',

  components: {
  },

  data() {
    return {
      menu2: false,
      // orderFormItems: [
      //   {
      //     personName: '翠花',
      //     functionItems: [
      //       {
      //         functionName: '印花',
      //         functionNumber: '20',
      //         functionPrice: 20,
      //         sumPrice: 300,
      //       },
      //     ],
      //   },
      // ],

      factoryFunctions: ['印花', '织布', '核查', '粗沙'],

      orderFormDetail: {},
      orderFormName: '',
      orderFormDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      orderFormNum: 0,
      factoryID: '',
      personName: '',
      orderFormType: [],

      selectedFile: null,
      fileMax: 10 * 1024 * 1024,
      photoLoading: false,
      pictureUrl: '',
    }
  },

  created() {
    this.$store.watch(
      (state) => state.userMsg.factoryID, () => {
        this.factoryID = this.$store.getters.getfactoryID;
      }, {
        deep: true
      }
    );
    this.factoryID = this.$store.getters.getfactoryID;

    this.$store.watch(
      (state) => state.userMsg.personName, () => {
        this.personName = this.$store.getters.getpersonName;
      }, {
        deep: true
      }
    );
    this.personName = this.$store.getters.getpersonName;

    this.$store.watch(
      (state) => state.factoryMsg.factoryFunctions, () => {
        this.factoryFunctions = this.$store.getters.getfactoryFunctions;
      }, {
        deep: true
      }
    );
    this.factoryFunctions = this.$store.getters.getfactoryFunctions;

  },

  mounted() {
  },

  activated() {
    this.orderFormDetail = JSON.parse(this.$route.params.orderFormDetail);
    this.handleModifyOrderFormType()
  },

  methods: {
    handleNumChange(index, functionIndex) {
      this.orderFormType[index].functionItems[functionIndex].sumPrice = (this.orderFormType[index].functionItems[functionIndex].functionNumber * this.orderFormType[index].functionItems[functionIndex].functionPrice).toFixed(1);
      // item.sumPrice = (item.functionNumber * item.functionPrice).toFixed(1)
      this.handleSaveModify()
    },
    
    handleModifyOrderFormType() {
      this.orderFormName = this.orderFormDetail.orderFormName
      this.orderFormDate = this.orderFormDetail.orderFormDate
      this.orderFormNum = this.orderFormDetail.orderFormNum
      this.orderFormType = JSON.parse(this.orderFormDetail.orderFormType)
    },

    async handleBack() {
      this.$store.commit('HandleloadingMsg', '保存中');
      await this.handleSaveModify()
      this.$store.commit('HandleloadingMsg', '');
      let url='/OrderForm';
      if (this.$route.path !== url) {
        this.$router.push({ path: url});
      }
    },

    async handleSaveModify() {
      if (this.orderFormName !== '') {
        let request_header = {
          orderFormID: this.orderFormDetail.orderFormID,
        };
        let request_json = {
          orderFormDate: this.orderFormDate,
          orderFormName: this.orderFormName,
          orderFormNum: this.orderFormNum,
          factoryID: this.factoryID,
          personID: getLocalStorage('kuaigongxuUser'),
          personName: this.personName,
          pictureUrl: this.pictureUrl,
          orderFormType: JSON.stringify(this.orderFormType),
        };

        let request_url = '/requestModifyOrderForm';
        const res = await requestPost(request_header, request_json, request_url);

        if (res.statusCode == 1) {
          this.$store.commit('HandleSnackbar', '修改表单成功');
        } else {
          this.$store.commit('HandleSnackbar', 'Failed to retrieve user information:' + res.statusInfo);
        }
      } else {
        this.$store.commit('HandleSnackbar', '请输入流转单名');
      }
    },
    
    handleAddStep(item, index) {
      let functionItem = {
        functionName: item.functionName,
        functionID: item.functionID,
        functionPrice: item.functionPrice,
        functionNumber: 0,
        sumPrice: 0,
      }
      this.orderFormType[index].functionItems.push(functionItem)
    },

    handleDeleteStep(index, functionIndex) {
      this.orderFormType[index].functionItems.splice(functionIndex, 1);
      this.handleSaveModify()
    },

    openFileInput() {
      this.$refs.fileInput.click();
    },

    async handleFileUpload(event) {
      this.selectedFile = event.target.files[0];
      if (this.selectedFile.size < this.fileMax) {
        this.photoLoading = true;
        const formData = new FormData();

        formData.append('fileName', this.selectedFile.name);
        formData.append('fileBody', this.selectedFile);

        let request_header = {
          orderFormID: this.orderFormDetail.orderFormID,
        };
        let request_json = formData;

        let request_url = '/requestUploadImage';
        const res = await this.requestPost_pic(request_header, request_json, request_url);

        if (res.statusCode == 1) {
          this.pictureUrl = res.data.filePath;
          this.orderFormDetail.pictureUrl = res.data.filePath;
          this.photoLoading = false;
          this.handleSaveModify()
        } else {
          this.$refs.YA_SnackbarRef.handleSnackbar('上传失败： ' + res.statusInfo);
          this.photoLoading = false;
        }
      } else {
        this.$refs.YA_SnackbarRef.handleSnackbar('图片太大了。');
        this.selectedFile = null;
        
      }
    },

    utoa(data) {
      return btoa(unescape(encodeURIComponent(data)));
    },

    async requestPost_pic(request_header, request_json, url) {
      let baseURL=getBaseURL()
      let requestUrl =`${baseURL}${url}`
      const response = await fetch(requestUrl, {
        method: 'POST',
        headers: request_header,
        body: request_json
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8")

      const { value } = await reader.read();
      let decodedValue = decoder.decode(value);
      let realValue = JSON.parse(decodedValue)
      return realValue
    },

    handleDeleteFile() {
      this.pictureUrl = '';
      this.orderFormDetail.pictureUrl = '';
      this.handleSaveModify()
    }
  }
};
</script>
