<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="dates"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="dateRangeText"
          label="日期选择"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
          color="#439798"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="dates"
        locale="ch"
        no-title
        range
      >
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="menu = false"
        >
          取消
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="handleTimeSelect"
        >
          确认
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'
import { requestPost } from '@/utils/SystemUtils/request.js';

export default {
  name: 'PriceTimeComponent',

  components: {
  },

  data() {
    return {
      dates: [(new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() + 24 * 60 * 60 * 1000).toISOString().substr(0, 10)), (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)],

      menu: false,

      factoryID: '',
      factoryName: '',

      personID: '',
      charaterID: '',
      personName: '',
    }
  },

  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    },
  },

  created() {
    this.$store.watch(
      (state) => state.userMsg.personID, () => {
        this.personID = this.$store.getters.getpersonID;
      }, {
        deep: true
      }
    );
    this.personID = this.$store.getters.getpersonID;

    this.$store.watch(
      (state) => state.userMsg.charaterID, () => {
        this.charaterID = this.$store.getters.getcharaterID;
      }, {
        deep: true
      }
    );
    this.charaterID = this.$store.getters.getcharaterID;

    this.$store.watch(
      (state) => state.userMsg.personName, () => {
        this.personName = this.$store.getters.getpersonName;
      }, {
        deep: true
      }
    );
    this.personName = this.$store.getters.getpersonName;

    this.$store.watch(
      (state) => state.userMsg.factoryID, () => {
        this.factoryID = this.$store.getters.getfactoryID;
      }, {
        deep: true
      }
    );
    this.factoryID = this.$store.getters.getfactoryID;

    this.$store.watch(
      (state) => state.factoryMsg.factoryName, () => {
        this.factoryName = this.$store.getters.getfactoryName;
        this.handleGetPriceForm()
      }, {
        deep: true
      }
    );
    this.factoryName = this.$store.getters.getfactoryName;
  },

  mounted() {
  },

  activated() {
    this.handleGetPriceForm()
  },

  methods: {
    handleTimeSelect() {
      this.$refs.menu.save(this.dates)
      this.handleGetPriceForm()
    },

    async handleGetPriceForm() {
      if (this.factoryName !== '') {
        this.$store.commit('HandleloadingMsg', '获取工资单中');

        let request_header = {
          priceFormDates: JSON.stringify(this.dates),
        };

        let request_json = {
          factoryID: this.factoryID,
          personID: this.personID,
          charaterID: this.charaterID,
          personName: this.personName
        };

        let request_url = '/requestPriceForm';
        const res = await requestPost(request_header, request_json, request_url);

        if (res.statusCode == 1) {
          this.$store.commit('HandlepriceFormItems', res.data);

          this.$store.commit('HandleloadingMsg', '');
        } else {
          this.$store.commit('HandleSnackbar', 'Failed to retrieve user information:' + res.statusInfo);
        }
      }
      
    },
  }
};
</script>
