<template>
  <div>
    <div class="d-flex justify-center">
      <v-btn
        color="#439798"
        class="white--text"
        @click="handleNewOrderForm"
      >
        <span class="mx-6">新建流转单</span>
      </v-btn>
    </div>

    <v-list three-line>
      <div v-for="(item, index) in orderFormItems" :key="index" @click="handleOrderForm(index)">
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <span class="text-body-1 font-weight-bold">{{ item.orderFormName }}</span>
              <v-btn icon small class="ml-4" @click.stop="handleDelete(item.orderFormID, item.orderFormName)">
                <v-icon size="24" color="grey lighten-1">mdi-delete</v-icon>
              </v-btn>
            </v-list-item-title>
            <v-list-item-subtitle>
              <div class="ml-4">
                <span class="text-body-2">创建人：{{ item.personName }}</span>
              </div>
              
              <div class="mt-auto ml-4">
                <span class="text-body-2 grey--text font-weight-bold">创建时间：{{ item.orderFormDate }}</span>
                <!-- <span class="ml-4 text-body-2 grey--text font-weight-bold">更新时间：{{ item.updateTime }}</span> -->
              </div>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon>
              <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </div>
    </v-list>

    <v-dialog
      v-model="deleteDialog"
      width="500"
    >
      <v-card>
        <v-card-title>
          确定删除 {{ orderFormDeleteName }} 吗 ?
        </v-card-title>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="#439798"
            text
            @click="deleteDialog = false"
            class='text-capitalize font-weight-bold'
          >
            取消
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="#439798"
            @click="handleSureDelete"
            :loading="sureDeleteloading"
            class='text-capitalize white--text font-weight-bold'
          >
            确定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="addDialog"
      width="500"
    >
      <v-card>
        <v-card-title>
          增加流转单
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
              <v-text-field
                v-model="orderFormAddName"
                counter
                color="#439798"
                maxlength="20"
                label="流转单名"
                prepend-icon="mdi-rename"
                required
              ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="orderFormAddDate"
                      label="流转单日期"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      color="#439798"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="orderFormAddDate"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="#439798"
            text
            @click="addDialog = false"
            class='text-capitalize font-weight-bold'
          >
            取消
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="#439798"
            @click="handleSureAdd"
            :loading="sureAddloading"
            class='text-capitalize white--text font-weight-bold'
          >
            确定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'
import { requestPost } from '@/utils/SystemUtils/request.js';


export default {
  name: 'FormComponent',

  components: {
  },

  data() {
    return {
      orderFormItems: [],
      factoryPersons: [],
      factoryID: '',
      personName: '',

      orderFormDeleteID: '',
      orderFormDeleteName: '',
      deleteDialog: false,
      sureDeleteloading: false,

      addDialog: false,
      orderFormAddName: '',
      orderFormAddDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      orderFormType: {},
      menu2: false,
      sureAddloading: false,
    }
  },

  created() {
    this.$store.watch(
      (state) => state.userMsg.factoryID, () => {
        this.factoryID = this.$store.getters.getfactoryID;
      }, {
        deep: true
      }
    );
    this.factoryID = this.$store.getters.getfactoryID;

    this.$store.watch(
      (state) => state.userMsg.personName, () => {
        this.personName = this.$store.getters.getpersonName;
      }, {
        deep: true
      }
    );
    this.personName = this.$store.getters.getpersonName;

    this.$store.watch(
      (state) => state.orderFormMsg.orderFormItems, () => {
        this.orderFormItems = this.$store.getters.getorderFormItems;
      }, {
        deep: true
      }
    );
    this.orderFormItems = this.$store.getters.getorderFormItems;

    this.$store.watch(
      (state) => state.factoryMsg.factoryPersons, () => {
        this.factoryPersons = this.$store.getters.getfactoryPersons;
      }, {
        deep: true
      }
    );
    this.factoryPersons = this.$store.getters.getfactoryPersons;
  },

  mounted() {
  },

  methods: {
    handleOrderForm(index) {
      let orderFormDetailJSON = JSON.stringify(this.orderFormItems[index])
      
      let name='Detail';
      if (this.$route.name !== name) {
        this.$router.push({ name: name, params: { orderFormDetail: orderFormDetailJSON }});
      }
    },

    handleDelete(ID, Name) {
      this.deleteDialog = true;
      this.orderFormDeleteID = ID;
      this.orderFormDeleteName = Name;
    },

    async handleSureDelete() {
      this.sureDeleteloading = true;

      let request_header = {
        orderFormID: this.orderFormDeleteID,
      };
      let request_json = {};

      let request_url = '/requestDeleteOrderForm';
      const res = await requestPost(request_header, request_json, request_url);

      if (res.statusCode == 1) {
        this.sureDeleteloading = false;
        this.deleteDialog = false;
        
        this.$store.commit('HandlerefreshOrderFormItems', true);
      } else {
        this.$store.commit('HandleSnackbar', 'Failed to retrieve user information:' + res.statusInfo);
      }
    },

    handleNewOrderForm() {
      this.addDialog = true;
      if (this.factoryPersons.length > 0) {
        this.handleInitOrderFormType()
      }
    },

    handleInitOrderFormType() {
      this.orderFormType = [];
      let orderFormTypeItem = {}
      this.factoryPersons.forEach((item, index)=> {
        if (item.charaterID == '003') {
          orderFormTypeItem['personID'] = item.personID
          orderFormTypeItem['personName'] = item.personName
          orderFormTypeItem['functionItems'] = []
          this.orderFormType.push(orderFormTypeItem)
          orderFormTypeItem = {}
        }
      })
      this.orderFormName = ''
    },

    async handleSureAdd() {
      if (this.orderFormAddName !== '') {
        this.sureAddloading = true

        let request_header = {
          personID: getLocalStorage('kuaigongxuUser'),
        };
        let request_json = {
          orderFormDate: this.orderFormAddDate,
          orderFormName: this.orderFormAddName,
          factoryID: this.factoryID,
          personName: this.personName,
          orderFormType: JSON.stringify(this.orderFormType),
        };

        let request_url = '/requestNewOrderForm';
        const res = await requestPost(request_header, request_json, request_url);

        if (res.statusCode == 1) {
          this.sureAddloading = false
          this.addDialog = false;
          this.orderFormAddDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
          this.orderFormAddName = '';
          this.orderFormType = [];
          this.$store.commit('HandleSnackbar', '增加表单成功');

          this.$store.commit('HandlerefreshOrderFormItems', true);
        } else {
          this.$store.commit('HandleSnackbar', 'Failed to retrieve user information:' + res.statusInfo);
        }
      } else {
        this.$store.commit('HandleSnackbar', '请输入流转单名');
      }

    },
  }
};
</script>
