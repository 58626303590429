<template>
  <div>
    <v-bottom-navigation
      :value="value"
      color="#439798"
      grow
      app
      v-if="isLogin"
    >
      <v-btn @click="handlePriceForm">
        <span>工资表</span>

        <v-icon>mdi-currency-usd</v-icon>
      </v-btn>

      <v-btn @click="handleOrderForm" v-if="!(charaterID == '003')">
        <span>流转单</span>

        <v-icon>mdi-table-large</v-icon>
      </v-btn>

      <v-btn @click="handleSettingForm" v-if="!(charaterID == '003')">
        <span>信息配置</span>

        <v-icon>mdi-cog</v-icon>
      </v-btn>

      <!-- <v-btn>
        <span>Nearby</span>

        <v-icon>mdi-map-marker</v-icon>
      </v-btn> -->
    </v-bottom-navigation>
  </div>
</template>

<script>
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'
import { requestPost } from '@/utils/SystemUtils/request.js';

export default {
  name: 'BottomView',

  components: {
  },

  data() {
    return {
      value: undefined,

      isLogin: false,
      charaterID: '',
    }
  },

  created() {
    this.$store.watch(
      (state) => state.userMsg.isLogin, () => {
        this.isLogin = this.$store.getters.getisLogin;
      }, {
        deep: true
      }
    );
    this.isLogin = this.$store.getters.getisLogin;

    this.$store.watch(
      (state) => state.userMsg.charaterID, () => {
        this.charaterID = this.$store.getters.getcharaterID;
      }, {
        deep: true
      }
    );
    this.charaterID = this.$store.getters.getcharaterID;

    this.$store.watch(
      (state) => state.systemMsg.nowRouter, () => {
        this.handleFocus();
      }, {
        deep: true
      }
    );
    this.handleFocus();
  },

  methods: {
    handlePriceForm() {
      let url='/PriceForm';
      if (this.$route.path !== url) {
        this.$router.push({ path: url});
      }
    },

    handleOrderForm() {
      let url='/OrderForm';
      if (this.$route.path !== url) {
        this.$router.push({ path: url});
      }
    },

    handleSettingForm() {
      let url='/SettingForm';
      if (this.$route.path !== url) {
        this.$router.push({ path: url});
      }
    },

    handleFocus() {
      if (this.$store.getters.getnowRouter) {
        if (this.$store.getters.getnowRouter == "Detail") {
          this.value = 1
        } else if (this.$store.getters.getnowRouter == "PriceForm") {
          this.value = 0
        } else if (this.$store.getters.getnowRouter == "OrderForm") {
          this.value = 1
        } else if (this.$store.getters.getnowRouter == "SettingForm") {
          this.value = 2
        }
      }
    },
  }
};
</script>

<style scoped>
</style>
