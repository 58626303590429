import Vue from 'vue';
import VueRouter from 'vue-router';
import store from "@/store/index"; 

// import AppView from '@/App.vue';

import BeginView from '@/views/BeginView.vue';

import PriceFormView from '@/views/FuncView/PriceFormView.vue';
import OrderFormView from '@/views/FuncView/OrderFormView.vue';
import SettingFormView from '@/views/FuncView/SettingFormView.vue';

import DetailComponent from '@/components/OrderFormComponents/DetailComponent.vue';

import Login from '@/components/SocialComponents/YA_Login.vue';


Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'BeginView',
    component: BeginView,
    redirect: 'Login',
    meta:{
      'title':'kuaigongxu'
    },
    children: [
      {
        path: 'Login',
        name: 'Login',
        component: Login,
        meta:{
          'title':'Login - kuaigongxu'
        },
      },
      {
        path: 'PriceForm',
        name: 'PriceForm',
        component: PriceFormView,
        meta:{
          'title':'工资表 - kuaigongxu'
        },
      },
      {
        path: 'OrderForm',
        name: 'OrderForm',
        component: OrderFormView,
        meta:{
          'title':'流转单 - kuaigongxu'
        },
      },
      {
        path: 'SettingForm',
        name: 'SettingForm',
        component: SettingFormView,
        meta:{
          'title':'信息配置 - kuaigongxu'
        },
      },
      {
        path: 'Detail/:orderFormDetail',
        name: 'Detail',
        component: DetailComponent,
        meta:{
          'title':'流转单 - kuaigongxu'
        },
      },
    ]
  },
  
  {
    path:"*",
    redirect:'/Home'
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to,from,next)=>{
  if(to.meta.title){
    document.title=to.meta.title
  }
  store.commit('HandlenowRouter', to.name);

  if (to.name !== 'Login') {
    let isLogin = store.getters.getisLogin;
    if (!isLogin) {
      next('/Login');
    } else {
      if (to.name == 'OrderForm' || to.name == 'Detail' || to.name == 'SettingForm') {
        let charaterID = store.getters.getcharaterID;
        if (charaterID == '003') {
          next('/PriceForm');
        }
      }
    }
  }

  next()
});




export default router;
