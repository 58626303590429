
<template>
  <div class="text-center">
    <v-overlay :value="loading">
      <v-dialog
        v-model="loading"
        hide-overlay
        persistent
        width="300"
      >
        <v-card
          class="pt-3"
          color="#296162"
          dark
        >
          <v-card-text>
            <p class="mb-2 font-weight-bold white--text">{{ loadingMsg }}</p>
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-overlay>
  </div>
</template>

<script>

export default {
  name: 'YA_Loading',
  data() {
    return {
      loadingMsg: '',
    }
  },

  computed: {
    loading() {
      if (this.loadingMsg == "") {
        return false
      } else {
        return true
      }
    },
  },

  created() {
    this.$store.watch(
      (state) => state.systemMsg.loadingMsg, () => {
        this.loadingMsg = this.$store.getters.getloadingMsg;
      }, {
        deep: true
      }
    );
    this.loadingMsg = this.$store.getters.getloadingMsg;
  },

  mounted() {

  },

  methods: {
  }

};
</script>

<style scoped>

</style>