import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router';
import store from "./store/index"; 

Vue.config.productionTip = false

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
