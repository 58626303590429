<template>
  <div>
    <div class="d-flex justify-center">
      <v-btn
        color="#439798"
        class="white--text"
        @click="handleAddFunction"
      >
        <span class="mx-6">新建工序</span>
      </v-btn>
    </div>
    <v-list two-line>
      <div v-for="(item, index) in factoryFunctions" :key="index">
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="d-flex align-center">
              <span class="text-body-1 font-weight-bold">{{ item.functionName }}</span>
              <span class="ml-2 text-body-2 grey--text">¥{{ item.functionPrice }}</span>
              <v-btn icon @click="handleModifyFunction(item.functionID, item.functionName, item.functionPrice)" class="ml-2">
                <v-icon color="grey">mdi-pencil</v-icon>
              </v-btn>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="handleDeleteFunction(item.functionID, item.functionName)">
              <v-icon color="grey">mdi-delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </div>
    </v-list>
    <v-dialog
      v-model="deleteDialog"
      width="500"
    >
      <v-card>
        <v-card-title>
          确定删除 {{ functionDeleteName }} 吗 ?
        </v-card-title>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="#439798"
            text
            @click="deleteDialog = false"
            class='text-capitalize font-weight-bold'
          >
            取消
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="#439798"
            @click="handleSureDelete"
            :loading="sureDeleteloading"
            class='text-capitalize white--text font-weight-bold'
          >
            确定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="addDialog"
      width="500"
    >
      <v-card>
        <v-card-title>
          增加工序
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  label="请输入工序名称*"
                  required
                  color="#439798"
                  v-model="functionAddName"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  label="请输入工序价格*"
                  required
                  color="#439798"
                  v-model="functionAddPrice"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="#439798"
            text
            @click="addDialog = false"
            class='text-capitalize font-weight-bold'
          >
            取消
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="#439798"
            @click="handleSureAdd"
            :loading="sureAddloading"
            class='text-capitalize white--text font-weight-bold'
          >
            确定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'
import { requestPost } from '@/utils/SystemUtils/request.js';

export default {
  name: 'FunctionSettingComponent',

  components: {
  },

  data() {
    return {
      factoryFunctions: [],

      addDialog: false,
      functionMode: '',
      functionModifyID: '',
      functionAddPrice: '',
      functionAddName: '',
      sureAddloading: false,

      functionDeleteID: '',
      functionDeleteName: '',
      deleteDialog: false,
      sureDeleteloading: false,

      factoryID: '',
    }
  },

  created() {
    this.$store.watch(
      (state) => state.factoryMsg.factoryFunctions, () => {
        this.factoryFunctions = this.$store.getters.getfactoryFunctions;

      }, {
        deep: true
      }
    );
    this.factoryFunctions = this.$store.getters.getfactoryFunctions;

    this.$store.watch(
      (state) => state.userMsg.factoryID, () => {
        this.factoryID = this.$store.getters.getfactoryID;
      }, {
        deep: true
      }
    );
    this.factoryID = this.$store.getters.getfactoryID;
  },

  mounted() {
  },

  methods: {
    handleDeleteFunction(functionID, functionName) {
      this.deleteDialog = true;
      this.functionDeleteID = functionID;
      this.functionDeleteName = functionName;
    },

    handleModifyFunction(functionID, functionName, functionPrice) {
      this.functionMode = 'Modify';
      this.addDialog = true;
      this.functionModifyID = functionID;
      this.functionAddName = functionName;
      this.functionAddPrice = functionPrice;
    },

    handleAddFunction() {
      this.functionMode = 'New';
      this.addDialog = true;
    },

    async handleSureDelete() {
      this.sureDeleteloading = true;

      let request_header = {
        functionID: this.functionDeleteID
      };
      let request_json = {};

      let request_url = '/requestDeleteFunction';
      const res = await requestPost(request_header, request_json, request_url);

      if (res.statusCode == 1) {
        this.sureDeleteloading = false;
        this.deleteDialog = false;
        this.functionDeleteID = ''
        
        this.$store.commit('HandlerefreshFactoryMsg', true);
      } else {
        this.$store.commit('HandleSnackbar', 'Failed to retrieve user information:' + res.statusInfo);
      }
    },

    async handleSureAdd() {
      if ((this.functionAddName !== '') && (this.functionAddPrice !== '')) {
        if (this.functionMode == 'New') {
          this.sureAddloading = true;

          let request_header = {};
          let request_json = {
            factoryID: this.factoryID,
            functionID: this.getNewFunctionID(),
            functionName: this.functionAddName,
            functionPrice: this.functionAddPrice
          };

          let request_url = '/requestNewFunction';
          const res = await requestPost(request_header, request_json, request_url);

          if (res.statusCode == 1) {
            this.sureAddloading = false;
            this.addDialog = false;
            this.functionAddName = '';
            this.functionAddPrice = '';
            this.functionMode = '';
            
            this.$store.commit('HandlerefreshFactoryMsg', true);
          } else {
            this.$store.commit('HandleSnackbar', 'Failed to retrieve user information:' + res.statusInfo);
          }
        } else if (this.functionMode == 'Modify') {
          this.sureAddloading = true;

          let request_header = {
            functionID: this.functionModifyID
          };
          let request_json = {
            functionName: this.functionAddName,
            functionPrice: this.functionAddPrice
          };

          let request_url = '/requestModifyFunction';
          const res = await requestPost(request_header, request_json, request_url);

          if (res.statusCode == 1) {
            this.sureAddloading = false;
            this.addDialog = false;
            this.functionModifyID = '';
            this.functionAddName = '';
            this.functionAddPrice = '';
            this.functionMode = '';
            
            this.$store.commit('HandlerefreshFactoryMsg', true);
          } else {
            this.$store.commit('HandleSnackbar', 'Failed to retrieve user information:' + res.statusInfo);
          }
        }
        
      } else {
        this.$store.commit('HandleSnackbar', '请输入用户名与密码');
      }
    },

    getNewFunctionID() {
      let allNumber = []
      this.factoryFunctions.forEach((item, index)=> {
        let number = parseInt(item.functionID.replace(item.factoryID + 'GX', ""));
        allNumber.push(number)
      })
      allNumber.sort((a, b) => a - b);
      
      let minNumber = 1;
      for (const num of allNumber) {
        if (num === minNumber) {
          minNumber++;
        } else {
          break;
        }
      }
      return this.factoryID + 'GX' + minNumber.toString().padStart(3, '0');
    }
  },
};
</script>
