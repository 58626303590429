<template>
  <div class="mx-2">
    <v-tabs
      v-model="tab"
      background-color="transparent"
      color="basil"
      grow
    >
      <v-tab
        v-for="item in items"
        :key="item"
      >
        {{ item }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="(item, index) in items"
        :key="item"
      >
        <PersonSettingComponent v-if="index == 0" class="mt-4" />
        <FunctionSettingComponent v-if="index == 1" class="mt-4" />
        
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'
import PersonSettingComponent from "@/components/SettingFormComponents/PersonSettingComponent.vue";
import FunctionSettingComponent from "@/components/SettingFormComponents/FunctionSettingComponent.vue";

export default {
  name: 'SettingFormView',

  components: {
    PersonSettingComponent,
    FunctionSettingComponent,
  },

  data() {
    return {
      tab: undefined,
      items: [
        '人员配置', '工序配置',
      ],
    }
  },

  created() {
  },

  mounted() {
  },

  methods: {
  }
};
</script>
