
<template>
  <div>
    <v-menu 
      offset-y 
      no-border
      rounded
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-avatar v-on="on" v-bind="attrs" size="30" color="#296162">
          <!-- <v-img alt="avatar" contain :src="photoUrl" /> -->
          <v-icon size="30" class="ml-1" color="white">
            mdi-account-circle
          </v-icon>
        </v-avatar>
      </template>
      <v-card>
        <v-list color="transparent">
          <v-list-item>
            <v-list-item-avatar color="indigo">
              <v-icon dark>
                mdi-account-circle
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="grey--text text--darken-4">{{ personName }}</v-list-item-title>
              <v-list-item-subtitle class="grey--text text--darken-2">{{ personID }}</v-list-item-subtitle>
            </v-list-item-content>

          </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <div>
          <div class="d-flex mt-4 align-center justify-center">
            <h5 class="text-center">角色：</h5>
            <v-icon color="amber accent-4" v-if="charaterID == ('001' || '002')">mdi-crown</v-icon>
            <h5 class="text-center" v-if="charaterID == '001'">超级管理员</h5>
            <h5 class="text-center" v-if="charaterID == '002'">管理员</h5>
            <h5 class="text-center" v-if="charaterID == '003'">员工</h5>
          </div>
          <div class="d-flex mb-4 align-center justify-center">
            <h5 class="text-center">工厂：</h5>
            <h5 class="text-center">{{ factoryName }}</h5>
          </div>
          
        </div>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="error"
            text
            @click="handleLogout"
            class="text-capitalize"
          >
            Sign out
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'

export default {
  name: 'YA_User',
  data() {
    return {
      personID: '',
      charaterID: false,
      personName: '',

      factoryName: '',
    }
  },

  created() {
    this.$store.watch(
      (state) => state.userMsg.personID, () => {
        this.personID = this.$store.getters.getpersonID;
      }, {
        deep: true
      }
    );
    this.personID = this.$store.getters.getpersonID;

    this.$store.watch(
      (state) => state.userMsg.personName, () => {
        this.personName = this.$store.getters.getpersonName;
      }, {
        deep: true
      }
    );
    this.personName = this.$store.getters.getpersonName;

    this.$store.watch(
      (state) => state.userMsg.charaterID, () => {
        this.charaterID = this.$store.getters.getcharaterID;
      }, {
        deep: true
      }
    );
    this.charaterID = this.$store.getters.getcharaterID;

    this.$store.watch(
      (state) => state.factoryMsg.factoryName, () => {
        this.factoryName = this.$store.getters.getfactoryName;
      }, {
        deep: true
      }
    );
    this.factoryName = this.$store.getters.getfactoryName;

  },

  mounted() {

  },

  methods: {
    handleLogout() {
      removeLocalStorage('kuaigongxuUser');

      this.$store.commit('HandleisLogin', false);
      this.$store.commit('HandlepersonID', '');
      this.$store.commit('HandlepersonName', '');
      this.$store.commit('HandlefactoryID', '');
      this.$store.commit('HandlecharaterID', '');
      this.$store.commit('HandlecharaterName', '');

      this.$store.commit('HandlefactoryName', '');
      this.$store.commit('HandlefactoryFunctions', []);
      this.$store.commit('HandlefactoryPersons', []);
      this.$store.commit('HandleorderFormItems', []);
      this.$store.commit('HandlepriceFormItems', []);


      if (this.$router.currentRoute.path !== '/Login') {
        this.$router.push('/Login');
      }
    },
  }

};
</script>

<style scoped>

</style>