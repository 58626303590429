<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="dates"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="dateRangeText"
          label="日期选择"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
          color="#439798"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="dates"
        locale="ch"
        no-title
        range
      >
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="menu = false"
        >
          取消
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="handleTimeSelect"
        >
          确认
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'
import { requestPost } from '@/utils/SystemUtils/request.js';

export default {
  name: 'TimeComponent',

  components: {
  },

  data() {
    return {
      dates: [(new Date(new Date(new Date().getFullYear(), new Date().getMonth(), 1).getTime() + 24 * 60 * 60 * 1000).toISOString().substr(0, 10)), (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)],
      menu: false,

      factoryID: '',
    }
  },

  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    },
  },

  created() {
    this.$store.watch(
      (state) => state.orderFormMsg.refreshOrderFormItems, () => {
        if (this.$store.getters.getrefreshOrderFormItems) {
          this.handleGetOrderForm()
        }
      }, {
        deep: true
      }
    );

    this.$store.watch(
      (state) => state.userMsg.factoryID, () => {
        this.factoryID = this.$store.getters.getfactoryID;
      }, {
        deep: true
      }
    );
    this.factoryID = this.$store.getters.getfactoryID;
  },

  mounted() {
  },

  activated() {
    this.handleGetOrderForm()
  },

  methods: {
    handleTimeSelect() {
      this.$refs.menu.save(this.dates)
      this.handleGetOrderForm()
    },

    async handleGetOrderForm() {
      this.$store.commit('HandleloadingMsg', '获取流转单中');

      let request_header = {
        orderFormDate: JSON.stringify(this.dates),
        factoryID: this.factoryID,
      };
      let request_json = {};

      let request_url = '/requestGetOrderForm';
      const res = await requestPost(request_header, request_json, request_url);

      if (res.statusCode == 1) {
        this.$store.commit('HandleorderFormItems', res.data);

        this.$store.commit('HandleloadingMsg', '');
        this.$store.commit('HandlerefreshOrderFormItems', false);
      } else {
        this.$store.commit('HandleSnackbar', 'Failed to retrieve user information:' + res.statusInfo);
      }
    },
  }
};
</script>
