<template>
  <div class="mx-2">
    <PriceTimeComponent class="mt-4" />
    <PriceDetailComponent class="mb-6" />
  </div>
</template>

<script>
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'
import PriceDetailComponent from "@/components/PriceFormComponents/PriceDetailComponent.vue";
import PriceTimeComponent from "@/components/PriceFormComponents/PriceTimeComponent.vue";

export default {
  name: 'PriceFormView',

  components: {
    PriceTimeComponent,
    PriceDetailComponent,
  },

  data() {
    return {
    }
  },

  created() {
  },

  mounted() {
  },

  methods: {
  }
};
</script>
