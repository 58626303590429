<template>
  <div class="mx-2">
    <TimeComponent class="mt-4" />
    <FormComponent class="mb-6" />
  </div>
</template>

<script>
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'
import FormComponent from "@/components/OrderFormComponents/FormComponent.vue";
import TimeComponent from "@/components/OrderFormComponents/TimeComponent.vue";

export default {
  name: 'OrderFormView',

  components: {
    FormComponent,
    TimeComponent,
  },

  data() {
    return {
    }
  },

  created() {
  },

  mounted() {
  },

  methods: {
  }
};
</script>
