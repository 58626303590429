<template>
  <div>
    <div class="d-flex justify-center">
      <v-btn
        color="#439798"
        class="white--text"
        @click="handleAddPerson"
      >
        <span class="mx-6">新建员工</span>
      </v-btn>
    </div>
    <v-list two-line>
      <div v-for="(item, index) in factoryPersons" :key="index">
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="d-flex align-center">
              <span class="text-body-1 font-weight-bold">{{ item.personName }}</span>
              <span class="ml-2 text-body-2 grey--text">#{{ item.personID }}</span>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="handleDeletePerson(item.personID, item.personName)">
              <v-icon color="grey">mdi-delete</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </div>
    </v-list>
    <v-dialog
      v-model="deleteDialog"
      width="500"
    >
      <v-card>
        <v-card-title>
          确定删除 {{ personDeleteName }} 吗 ?
        </v-card-title>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="#439798"
            text
            @click="deleteDialog = false"
            class='text-capitalize font-weight-bold'
          >
            取消
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="#439798"
            @click="handleSureDelete"
            :loading="sureDeleteloading"
            class='text-capitalize white--text font-weight-bold'
          >
            确定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="addDialog"
      width="500"
    >
      <v-card>
        <v-card-title>
          增加用户
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  label="请输入用户名称*"
                  required
                  color="#439798"
                  v-model="personAddName"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  label="请输入用户密码*"
                  required
                  color="#439798"
                  v-model="personAddCode"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="#439798"
            text
            @click="addDialog = false"
            class='text-capitalize font-weight-bold'
          >
            取消
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="#439798"
            @click="handleSureAdd"
            :loading="sureAddloading"
            class='text-capitalize white--text font-weight-bold'
          >
            确定
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'
import { requestPost } from '@/utils/SystemUtils/request.js';

export default {
  name: 'PersonSettingComponent',

  components: {
  },

  data() {
    return {
      factoryPersons: [],

      addDialog: false,
      personAddCode: '',
      personAddName: '',
      sureAddloading: false,

      personDeleteID: '',
      personDeleteName: '',
      deleteDialog: false,
      sureDeleteloading: false,

      factoryID: '',
    }
  },

  created() {
    this.$store.watch(
      (state) => state.factoryMsg.factoryPersons, () => {
        this.factoryPersons = this.$store.getters.getfactoryPersons;

      }, {
        deep: true
      }
    );
    this.factoryPersons = this.$store.getters.getfactoryPersons;

    this.$store.watch(
      (state) => state.userMsg.factoryID, () => {
        this.factoryID = this.$store.getters.getfactoryID;
      }, {
        deep: true
      }
    );
    this.factoryID = this.$store.getters.getfactoryID;
  },

  mounted() {
  },

  methods: {
    handleDeletePerson(personID, personName) {
      this.deleteDialog = true;
      this.personDeleteID = personID;
      this.personDeleteName = personName;
    },

    handleAddPerson() {
      this.addDialog = true;
    },

    async handleSureDelete() {
      this.sureDeleteloading = true;

      let request_header = {
        personID: this.personDeleteID
      };
      let request_json = {};

      let request_url = '/requestDeletePerson';
      const res = await requestPost(request_header, request_json, request_url);

      if (res.statusCode == 1) {
        this.sureDeleteloading = false;
        this.deleteDialog = false;
        this.personDeleteID = '';
        
        this.$store.commit('HandlerefreshFactoryMsg', true);
      } else {
        this.$store.commit('HandleSnackbar', 'Failed to retrieve user information:' + res.statusInfo);
      }
    },

    async handleSureAdd() {
      if ((this.personAddName !== '') && (this.personAddCode !== '')) {
        this.sureAddloading = true;

        let request_header = {};
        let request_json = {
          charaterID: '003',
          factoryID: this.factoryID,
          personID: this.getNewPersonID(),
          personName: this.personAddName,
          personCode: this.personAddCode
        };

        let request_url = '/requestNewPerson';
        const res = await requestPost(request_header, request_json, request_url);

        if (res.statusCode == 1) {
          this.sureAddloading = false;
          this.addDialog = false;
          this.personAddCode = '';
          this.personAddName = '';
          
          this.$store.commit('HandlerefreshFactoryMsg', true);
        } else {
          this.$store.commit('HandleSnackbar', 'Failed to retrieve user information:' + res.statusInfo);
        }
      } else {
        this.$store.commit('HandleSnackbar', '请输入用户名与密码');
      }
    },

    getNewPersonID() {
      let allNumber = []
      this.factoryPersons.forEach((item, index)=> {
        let number = parseInt(item.personID.replace(item.factoryID, ""));
        allNumber.push(number)
      })
      allNumber.sort((a, b) => a - b);
      
      let minNumber = 1;
      for (const num of allNumber) {
        if (num === minNumber) {
          minNumber++;
        } else {
          break;
        }
      }
      return this.factoryID + minNumber.toString().padStart(4, '0');
    }
  },
};
</script>
