import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

  const state= {
    userMsg: {
      isLogin: false,
      personID: '',
      personName: '',
      factoryID: '',
      charaterID: '',
      charaterName: '',
    },
    systemMsg: {
      Snackbar: '',
      loadingMsg: "",
      nowRouter: '',
    },
    factoryMsg: {
      factoryName: '',
      factoryFunctions: [],
      factoryPersons: [],
      refreshFactoryMsg: false,
    },
    orderFormMsg: {
      orderFormItems: [],
      refreshOrderFormItems: false,
    },
    priceFormMsg: {
      priceFormItems: [],
    },

  }

  const getters= {
    // userMsg
    getisLogin() {
      return state.userMsg.isLogin
    },
    getpersonID() {
      return state.userMsg.personID
    },
    getpersonName() {
      return state.userMsg.personName
    },
    getfactoryID() {
      return state.userMsg.factoryID
    },
    getcharaterID() {
      return state.userMsg.charaterID
    },
    getcharaterName() {
      return state.userMsg.charaterName
    },

    // systemMsg
    getSnackbar() {
      return state.systemMsg.Snackbar
    },
    getloadingMsg() {
      return state.systemMsg.loadingMsg
    },
    getnowRouter() {
      return state.systemMsg.nowRouter
    },

    // factoryMsg
    getfactoryName() {
      return state.factoryMsg.factoryName
    },
    getfactoryFunctions() {
      return state.factoryMsg.factoryFunctions
    },
    getfactoryPersons() {
      return state.factoryMsg.factoryPersons
    },
    getrefreshFactoryMsg() {
      return state.factoryMsg.refreshFactoryMsg
    },

    // orderFormMsg
    getorderFormItems() {
      return state.orderFormMsg.orderFormItems
    },
    getrefreshOrderFormItems() {
      return state.orderFormMsg.refreshOrderFormItems
    },

    // priceFormMsg
    getpriceFormItems() {
      return state.priceFormMsg.priceFormItems
    },
  }

  const mutations= {
    // userMsg
    HandleisLogin(state, data) {
      state.userMsg.isLogin = data; 
    },
    HandlepersonID(state, data) {
      state.userMsg.personID = data; 
    },
    HandlepersonName(state, data) {
      state.userMsg.personName = data; 
    },
    HandlefactoryID(state, data) {
      state.userMsg.factoryID = data; 
    },
    HandlecharaterID(state, data) {
      state.userMsg.charaterID = data; 
    },
    HandlecharaterName(state, data) {
      state.userMsg.charaterName = data; 
    },

    // systemMsg
    HandleSnackbar(state, data) {
      state.systemMsg.Snackbar = data; 
    },
    HandleloadingMsg(state, data) {
      state.systemMsg.loadingMsg = data; 
    },
    HandlenowRouter(state, data) {
      state.systemMsg.nowRouter = data; 
    },

    // factoryMsg
    HandlefactoryName(state, data) {
      state.factoryMsg.factoryName = data; 
    },
    HandlefactoryFunctions(state, data) {
      state.factoryMsg.factoryFunctions = data; 
    },
    HandlefactoryPersons(state, data) {
      state.factoryMsg.factoryPersons = data; 
    },
    HandlerefreshFactoryMsg(state, data) {
      state.factoryMsg.refreshFactoryMsg = data; 
    },

    // orderFormMsg
    HandleorderFormItems(state, data) {
      state.orderFormMsg.orderFormItems = data; 
    },
    HandlerefreshOrderFormItems(state, data) {
      state.orderFormMsg.refreshOrderFormItems = data; 
    },

    // priceFormMsg
    HandlepriceFormItems(state, data) {
      state.priceFormMsg.priceFormItems = data; 
    },

  }

export default new Vuex.Store({
    mutations:mutations,
    state:state,
    getters:getters,
})