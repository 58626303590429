<template>
  <div>
    <v-expansion-panels flat outlined accordion hover>
      <v-expansion-panel
        v-for="(priceFormItem, priceFormIndex) in priceFormItems"
        :key="priceFormIndex"
      >
        <v-divider></v-divider>
        <v-expansion-panel-header>
          <div class="d-flex justify-space-between">
            <div class="d-flex align-center">
              <span class="ml-2 text-body-1 font-weight-bold">{{ priceFormItem.personName }}</span>
              <!-- <span class="ml-2 text-body-2 grey--text">#{{ priceFormItem.personID }}</span> -->
            </div>
            <div class="mr-6 d-flex align-center">
              <span class="text-body-2 grey--text font-weight-bold mx-4">¥{{ priceFormItem.sumPrice }}</span>
            </div>
          </div>
          
          <template v-slot:actions>
            <v-icon color="#439798">
              $expand
            </v-icon>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-list dense nav>
            <div>
              <v-list-item v-for="(functionItem, functionIndex) in priceFormItem.priceItems" :key="functionIndex" @click="handleOrderForm(priceFormIndex, functionIndex)">
                <v-list-item-content>
                  <div class="d-flex align-center justify-space-between">
                    <span class="ml-2 text-body-2">{{ functionItem.orderFormName }}</span>
                    <span class="ml-2 text-body-2">{{ functionItem.orderFormDate }}</span>
                  </div>
                </v-list-item-content>
                <v-list-item-action>
                  <div class="mr-6 d-flex align-center">
                    <span class="text-body-2 grey--text font-weight-bold">¥{{ functionItem.orderFormPrice }}</span>
                  </div>
                </v-list-item-action>
              </v-list-item>
            </div>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { getLocalStorage, setLocalStorage, removeLocalStorage } from '@/utils/SystemUtils/localStorage.js'

export default {
  name: 'PriceDetailComponent',

  components: {
  },

  data() {
    return {
      priceFormItems: [],
      charaterID: '',
    }
  },

  created() {
    this.$store.watch(
      (state) => state.priceFormMsg.priceFormItems, () => {
        this.priceFormItems = this.$store.getters.getpriceFormItems;
      }, {
        deep: true
      }
    );
    this.priceFormItems = this.$store.getters.getpriceFormItems;

    this.$store.watch(
      (state) => state.userMsg.charaterID, () => {
        this.charaterID = this.$store.getters.getcharaterID;
      }, {
        deep: true
      }
    );
    this.charaterID = this.$store.getters.getcharaterID;
  },

  mounted() {
  },

  methods: {
    handleOrderForm(priceFormIndex, functionIndex) {
      if (this.charaterID == ('002' || '001')) {
        let orderFormDetailJSON = JSON.stringify(this.priceFormItems[priceFormIndex].priceItems[functionIndex].orderFormItem)
      
        let name='Detail';
        if (this.$route.name !== name) {
          this.$router.push({ name: name, params: { orderFormDetail: orderFormDetailJSON }});
        }
      }
    },
  }
};
</script>
